<template>
  <v-row class="ml-7" style="width: 96%">
    <v-col cols="6">
      <template>
        <v-card>
          <v-data-table
            :headers="WarehouseHeaders"
            :items="warehouses"
            :loading="loading"
            loading-text="Loading... Please wait"
            no-data-text="No Warehouse available"
            item-key="id"
            class="elevation-1"
            style="background: #f5f5f5"
          >
            <template v-slot:top>
              <v-toolbar
                elevation="2"
                style="background: #363636; font-size: 26px"
              >
                <p class="mb-0 white--text">Warehouse</p>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                color="primary"
                style="padding-left: 0px !important"
                text
                @click="viewDetailsWarehouse(item)"
                >Details</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </template>
    </v-col>
    <v-col cols="6">
      <template>
        <v-card>
          <v-data-table
            :headers="shopHeaders"
            :items="shops"
            :loading="loading"
            loading-text="Loading... Please wait"
            no-data-text="No Shop
             available"
            item-key="id"
            class="elevation-1"
            style="background: #f5f5f5"
          >
            <template v-slot:top>
              <v-toolbar
                elevation="2"
                style="background: #363636; font-size: 26px"
              >
                <p class="mb-0 white--text">Shop</p>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                color="primary"
                style="padding-left: 0px !important"
                text
                @click="viewDetails(item)"
                >Details</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      WarehouseHeaders: [
        {
          text: "Warehouse Name",
          align: "start",
          value: "warehouse_name",
        },
        { text: "Warehouse Location", value: "warehouse_location" },
        { text: "View", value: "actions", sortable: false },
      ],
      warehouses: [],
      shopHeaders: [
        {
          text: "Shop Name",
          align: "start",
          value: "shop_name",
        },
        { text: "Location", value: "shop_location" },
        { text: "View", value: "actions", sortable: false },
      ],
      shops: [],
      Logged_user: {},
      loading: true,
    };
  },
  methods: {
    initialize() {
      axios
        .get(`manager/unattend_products/${this.Logged_user.user_id}`)
        .then((response) => {
          this.loading = false;
          if (response.data.success) {
            this.warehouses = response.data.ware_house;
            this.shops = response.data.shop;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    viewDetailsWarehouse(item) {
      this.$router.push({
        path: "/merchant-managerdashboard/pages/orders/list",
        query: { id: item.id, flag: 1 },
      });
    },
    viewDetails(item) {
      this.$router.push({
        path: "/merchant-managerdashboard/pages/orders/list",
        query: { id: item.id, flag: 0 },
      });
    },
  },
  mounted() {
    this.Logged_user = JSON.parse(localStorage.getItem("user"));
    this.initialize();
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>